$cdnURL: "https://rest-filestore.whiteclover.uk/files/whiteclover.io";
.FooterSection {
  background-image: url($cdnURL + "/newassets/footer-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.Footer {
  display: flex;
  flex-direction: column;
  //background-color: #fdf6eb;
  width:75%;
  padding-top: 160px;
  padding-bottom: 136px;
  //border-top: 1px solid black;
  margin: 0 auto;

  @media screen and (max-width: 1920px) {
    width:75%;
    padding-top: 160px;
    padding-bottom: 136px;
  }

  @media screen and (max-width: 1440px) {
    padding-top: 102px;
    padding-bottom: 80px;
  }

  @media screen and (max-width: 1024px) {
    width:100%;
    padding: 72px 80px;
  }

  @media screen and (max-width: 760px) {
    padding: 72px 80px;
  }

  @media screen and (max-width: 550px) {
    padding: 72px 24px;
  }

  ul {
    list-style-type: none;
  }

  .footerOrganizer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (max-width: 760px) {
      flex-direction: column;
    }
  }
}

.list {
  width: 50%;
  display: flex;
  justify-content: space-between;

  li {
    &:hover {
      text-decoration: underline;
      color: #63435B;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 50%;
    flex-direction: column;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
  }
  div {
    padding-bottom: 40px;
  }
  li {
    padding-bottom: 8px;
    @media screen and (min-width: 1921px) {
      padding-bottom: 0.417vw; //8px;
    }
  }
}

.langButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 150px;
  height: 56px;
  padding: 12px 32px;
  gap: 8px;

  border: 1px solid #63435B;
  border-radius: 8px;

  margin-top: 50px;
  cursor: pointer;

  @media screen and (min-width: 1921px) {
    width: 7.813vw; //150px;
    height: 2.917vw; //56px;
  }

  img {
    width: 16px;
    height: 16px;

    @media screen and (min-width: 1921px) {
      width: 0.833vw; //16px;
      height: 0.833vw; //16px;
    }
  }
}

.createWedding {
  padding-top : 0px;
  width: 60%;
  max-width: 400px;

  @media screen and (max-width: 760px) {
    padding-top : 60px;
    width: 100%;
  }

  @media screen and (min-width: 1921px) {
    max-width: 20.833vw; //400px;
  }

  p {
    padding-top: 16px;
    padding-bottom: 16px;

    @media screen and (min-width: 1921px) {
      padding-top: 0.833vw; //16px;
      padding-bottom: 0.833vw; //16px;
    }
  }

  .footerButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 8px;
  }

  .createWeddingButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    gap: 8px;

    //width: 241px;
    //height: 56px;

    /* Brand Color / Orange 2 */

    background: #F9AC80;
    border-radius: 8px;
    color: #63435B;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    &:hover {
      background: transparent;
      color: #63435B;
      border: 1px solid #63435B;
    }
  }
  img {
    width: 100%;
    max-width: 385px;
    height: auto;

    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 325px;
    }

    @media screen and (max-width: 760px) {
      width: 100%;
    }

    @media screen and (min-width: 1921px){
      max-width: 20.052vw; //385px;
    }
  }
  .logInButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    //width: 90px;
    //height: 56px;

    /* Brand Color / Purple 1 */

    background: #63435B;
    border-radius: 8px;
    color: #F4F3F4;
    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    &:hover {
      background: transparent;
      color: #63435B;
      border: 1px solid #63435B;
    }
  }
}

.socialsCookieSection {
  border-top: 1px solid #281B24;
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  .wrapperSocialAndLanguage {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media screen and (max-width: 760px) {
      flex-direction: column;
    }
  }

  .socials {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin-top: 50px;

    width: 130px;

    @media screen and (max-width: 760px) {
      width: auto;
    }

    @media screen and (min-width: 1921px) {
      width: 6.771vw; //130px;
    }

    img {
      width: 32px;
      height: 32px;

      @media screen and (min-width: 1921px) {
        width: 1.667vw; //32px;
        height: 1.667vw; //32px;
      }
    }
  }

  p {
    //margin-top: 50px;
    color: #63435B;
  }
}

.languagesList {
  position: absolute;
  background-color: #FDF6EB;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;

  p {
    padding: 24px 32px;
    cursor: pointer;
    &:hover {
      background-color: #63435B;
      color: #F4F3F4;
      border-radius: 8px;
    }
  }
}
